import { ApplicationProvider } from '@griegconnect/krakentools-react-kraken-app'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import { ReactComponent as Slide1 } from './assets/slides/Slide1.svg'
import { ReactComponent as Slide2 } from './assets/slides/Slide2.svg'
import { ReactComponent as Slide3 } from './assets/slides/Slide3.svg'
import './config/i18n'
import {
  AutoAppSwitcher,
  isStavanger,
  isVisitDomain,
  selectSigninMethods,
  passwordlessDescriptionText,
  infoText,
  title,
  getFrontendConfigPath,
  isFullDev,
} from './AutoAppSwitcher'
import { LicenseInfo } from '@mui/x-license-pro'

LicenseInfo.setLicenseKey(
  '3c92ac2a13f79038b2fca55b538af70bTz03MzQ0MyxFPTE3MjU3NTAwMDAwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  },
})
const container = document.getElementById('root')
const root = createRoot(container!)

if (!isVisitDomain && !isFullDev && window.location.pathname.startsWith('/user')) {
  window.location.port === '3002'
    ? (window.location.port = '3001')
    : (window.location.hostname = window.location.hostname.replace('security', 'visit'))
}

root.render(
  <QueryClientProvider client={queryClient}>
    <ApplicationProvider
      name="Security"
      disableProfileCompletion
      profileMenuButtons={['Settings']}
      tool="security"
      confirmInvitation
      tenantsUrlMatchPathPrefix="/companies/:tenantRef"
      reloadOnTenantsChange={true}
      configurationFilePath={getFrontendConfigPath()}
      appBar={{ hideApplicationSwitcher: isVisitDomain }}
      i18n={{ languages: ['en-GB', 'nb-NO', 'sv-SE', 'fi-FI'] }}
      onboarding={{
        signInMethods: selectSigninMethods(),
        loginRedirectOnSingleSignInMethod: isVisitDomain,
        passwordlessDescription: passwordlessDescriptionText(),
        carouselElements:
          isVisitDomain && isStavanger
            ? [
                {
                  component: <Slide1 />,
                  title: title(),
                  description: infoText(),
                },
              ]
            : [
                {
                  component: <Slide1 />,
                  title: 'Self-service',
                  description: 'Apply for access permit in advance of any planned visits.',
                },
                {
                  component: <Slide2 />,
                  title: 'Access',
                  description: 'Use your personal mobile device as an electronic access permit.',
                },
                {
                  component: <Slide3 />,
                  title: 'Availability',
                  description: 'Available wherever you are, via web and app – on mobile, tablet and pc.',
                },
              ],
      }}
    >
      <AutoAppSwitcher />
    </ApplicationProvider>
  </QueryClientProvider>
)
