import { Navigation, useTenants, Tenant, tenantsAtom } from '@griegconnect/krakentools-react-kraken-app'
import { useServices } from './common/ServicesWrapper'
import { dashboardModuleNavs } from './routes/Dashboard/dashboardModuleNavs'
import { checksModuleNavs } from './routes/Checks/checksModuleNavs'
import { passingsModuleNavs } from './routes/Passings/passingsModuleNavs'
import { mapModuleNavs } from './routes/Map/mapModuleNavs'
import { settingsModuleNavs } from './routes/Settings/settingsModuleNavs'
import { useRecoilState, useRecoilValue } from 'recoil'
import { portConfigStore } from './stores/portStore'
import { PortConfig } from './common/ServicesWrapper/apis/SharedDataApi'
import { userModuleNavs } from './routes/HomeUser/userModuleNavs'
import { permitsModuleNavs } from './routes/Permits/permitsModuleNavs'
import { applicationsModuleNavs } from './routes/Applications/applicationsModuleNavs'
import { identitiesModuleNavs } from './routes/Identities/identitiesModuleNavs'
import { integrationsModuleNavs } from './routes/Access/accessModuleNavs'
import { accessContractsModuleNavs } from './routes/AccessContracts/accessContractsModuleNavs'
import { useApplicationContext, useUserInterface } from '@griegconnect/krakentools-react-kraken-app'
import { Modal, Slide, Button } from '@mui/material'
import ProfileSettings from './common/ProfileSettings'
import { courseAttendeesModuleNavs } from './routes/CourseAttendees/courseAttendeesModuleNavs'
import Close from '@mui/icons-material/Close'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { isPort } from './lib/access'
import { useQuery } from '@tanstack/react-query'
import { UserApi } from './common/ServicesWrapper/apis/UserApi'
import { vesselsModuleNavs } from './routes/Vessels/vesselsModuleNavs'
import { checksAtom } from './atoms/checksAtom'
import { userApplicationsAtom } from './atoms/userApplicationsAtom'
import { applicationsAtom } from './atoms/applicationsAtom'
import { useEffect } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    outline: 'none',
  },
  modalBg: {
    width: '100%',
    height: '100%',
    outline: 'none',
    backgroundColor: theme.palette.background.paper,
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontWeight: 'bolder',
    width: '100%',
  },
}))

export interface AccessDependencies {
  activeTenant: Tenant.Type | null
  portConfig: PortConfig
  permissions: Tenant.Permission[]
  userDetails: UserApi.Details
}

type ContentProps = {
  isVisitDomain: boolean
  isFullDev: boolean
}

export const Content = ({ isVisitDomain, isFullDev }: ContentProps) => {
  const classes = useStyles()
  const [portConfig, setPortConfig] = useRecoilState<PortConfig>(portConfigStore)
  const [tenants, setTenants] = useRecoilState<Tenant.Type[]>(tenantsAtom)
  const checks = useRecoilValue(checksAtom)
  const userApplications = useRecoilValue(userApplicationsAtom)
  const applications = useRecoilValue(applicationsAtom)
  const { activeTenant } = useTenants()
  const { services } = useServices()
  const { profileModalOpen, changeProfileModal } = useApplicationContext()
  const { tenantSelector } = useUserInterface()

  const details = useQuery<{
    userDetails: UserApi.Details
  }>(['contentConfigs'], async () => {
    if (activeTenant) {
      const portCfg = await services.sharedDataApi.getConfig(activeTenant.ref)
      setPortConfig(portCfg)
    }
    const [ports, userDetails] = await Promise.all([services.sharedDataApi.getPorts({}), services.userApi.details()])
    setTenants(tenants.filter((t) => ports.some((p) => p.tenant.id === t.id)))
    return { userDetails }
  })

  useEffect(() => {
    if (isVisitDomain) {
      tenantSelector(false)
    }
  }, []) // eslint-disable-line

  const permissions = tenants.flatMap((t) => t.permissions)

  return (
    <>
      <Modal className={classes.modal} open={profileModalOpen === 'Settings'}>
        <Slide in={profileModalOpen === 'Settings'} timeout={300} direction="up">
          <div className={classes.modalBg}>
            <div className={classes.topContainer}>
              <Button onClick={() => changeProfileModal(undefined)}>
                <Close />
              </Button>
            </div>
            <ProfileSettings services={services} />
          </div>
        </Slide>
      </Modal>
      <Navigation
        dependencies={{
          activeTenant,
          portConfig,
          permissions,
          userDetails: details.data?.userDetails || { visitHandler: [], contractOwner: [], identities: [] },
        }}
        navigation={[
          ...userModuleNavs(userApplications, isVisitDomain, isFullDev),
          ...dashboardModuleNavs,
          ...applicationsModuleNavs(applications),
          ...permitsModuleNavs,
          ...passingsModuleNavs,
          ...accessContractsModuleNavs,
          ...vesselsModuleNavs,
          ...checksModuleNavs(checks),
          ...courseAttendeesModuleNavs,
          ...identitiesModuleNavs,
          ...[
            ...(isPort({
              activeTenant,
              portConfig,
              permissions,
              userDetails: details.data?.userDetails || { visitHandler: [], contractOwner: [], identities: [] },
            })
              ? integrationsModuleNavs
              : []),
          ],
          ...mapModuleNavs,
          ...settingsModuleNavs,
        ]}
      />
    </>
  )
}

export default Content
